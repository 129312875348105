import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, me } from '../state/admin';
import { resetSelectedZone } from '../state/selected_zone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCoffee,
	faHouseChimney,
	faCarAlt,
	faCarSide,
	faQrcode,
	faCodeBranch,
	faComment,
	faCity,
	faScrewdriverWrench,
	faSignOutAlt,
	faUserGear,
	faPhotoFilm,
	faMapMarker,
	faMapLocation,
	faDisplay,
	faRoute,
	faMoneyBillAlt,
	faSearchDollar,
	faMoneyBillWave,
	faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";

import logo from '../images/Icon-256.png';

require('./AuthenticatedPage.scss');

const AuthenticatedPage = ({ actions, admin = {}, children }) => {
  useEffect(() => {
    actions.me();
  }, []);

  const onLogout = () => {
    actions.logout();
  };

  if (!admin) {
    navigate('/login/');
    return <div />;
  }

  const externalOperator = admin.externalOperator || false;

  return (
    <div className="container">
      <div className="navigation">
        <img src={logo} alt="Platform logo" className="navigation__logo" />
        <ul>
          <li>
            <a className="text_link" href="/">
              <FontAwesomeIcon icon={faHouseChimney} /> Home
            </a>
          </li>
          {!externalOperator && (
            <li>
              <a className="text_link" href="/pricing-plans">
                <FontAwesomeIcon icon={faMoneyBillWave} /> Pricing Plans
              </a>
            </li>
          )}
          {!externalOperator && (
            <li>
              <a className="text_link" href="/report-scooter">
                <FontAwesomeIcon icon={faCarSide} /> Vehicle Reports
              </a>
            </li>
          )}
          {!externalOperator && (
            <li>
              <a className="text_link" href="/users/edit">
                <FontAwesomeIcon icon={faUsersViewfinder} /> User Lookup
              </a>
            </li>
          )}
          {!admin.restricted_access && !externalOperator && (
            <li>
              <a className="text_link" href="/vehicles/edit">
                <FontAwesomeIcon icon={faCarAlt} /> Vehicle Lookup
              </a>
            </li>
          )}
          {!externalOperator && (
            <li>
              <a className="text_link" href="/ride">
                <FontAwesomeIcon icon={faSearchDollar} /> Ride Lookup
              </a>
            </li>
          )}
          {!admin.restricted_access && !externalOperator && (
            <li>
              <a className="text_link" href="/fine">
                <FontAwesomeIcon icon={faMoneyBillAlt} /> Fine Lookup
              </a>
            </li>
          )}
          {!admin.restricted_access && !externalOperator && (
            <li>
              <a className="text_link" href="/vehicles/add">
                <FontAwesomeIcon icon={faCarAlt} /> Add Vehicles
              </a>
            </li>
          )}
          {!externalOperator && (
            <li>
              <a className="text_link" href="/qr-codes">
                <FontAwesomeIcon icon={faQrcode} /> Print QR Codes
              </a>
            </li>
          )}
          {!externalOperator && (
            <li>
              <a className="text_link" href="/vehicles/firmware">
                <FontAwesomeIcon icon={faCodeBranch} /> Update Firmware
              </a>
            </li>
          )}
          {!admin.restricted_access && !externalOperator && (
            <li>
              <a className="text_link" href="/export-csv">
                <FontAwesomeIcon icon={faComment} /> Usage Reports
              </a>
            </li>
          )}
          <li>
            <span className="text_link" />
          </li>
          {!admin.restricted_access && (
            <li>
              <a className="text_link" href="/manage-market">
                <FontAwesomeIcon icon={faCity} /> Manage Market
              </a>
            </li>
          )}
          {!externalOperator && (
            <li>
              <a className="text_link" href="/users/add">
                <FontAwesomeIcon icon={faScrewdriverWrench} /> Add Ops User
              </a>
            </li>
          )}
          {!admin.restricted_access && (
            <li>
              <a className="text_link" href="/routes">
                <FontAwesomeIcon icon={faRoute} /> Create Routes
              </a>
            </li>
          )}
          {!admin.restricted_access && (
            <li>
              <a className="text_link" href="/manage-routes">
                <FontAwesomeIcon icon={faMapLocation} /> Assign Routes
              </a>
            </li>
          )}
          {!admin.restricted_access && (
            <li>
              <a className="text_link" href="/safety">
                Safety Reports
              </a>
            </li>
          )}
          {!admin.restricted_access && (
            <li>
              <a className="text_link" href="/ride-maps">
                <FontAwesomeIcon icon={faMapMarker} /> Ride Maps
              </a>
            </li>
          )}
          <li>
            <a className="text_link" href="/ride-photos">
              <FontAwesomeIcon icon={faPhotoFilm} /> Ride Photos
            </a>
          </li>
          <li>
            <span className="text_link" />
          </li>
          <li>
            <span className="text_link" />
          </li>
          <li>
            <a className="text_link" href="/admin-profile">
              <FontAwesomeIcon icon={faUserGear} /> Profile
            </a>
          </li>
          <li>
            <span className="text_link" onClick={onLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </span>
          </li>
        </ul>
      </div>
      <div
        style={{
          flexGrow: 1,
          margin: '1rem',
          marginLeft: '12rem',
        }}>
        {children}
      </div>
    </div>
  );
};

AuthenticatedPage.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  const { admin, selected_zone } = state;
  return { admin, selected_zone };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ logout, me, resetSelectedZone }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedPage);
